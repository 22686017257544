import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: any;
}

export const ROUTES: RouteInfo[] = [
  { path: './dashboard', title: 'Inicio', icon: 'nc-align-center', class: '', role: [1, 2, 5] },
  { path: './parking', title: 'Parqueadero', icon: 'nc-delivery-fast', class: '', role: [1, 2, 5] },
  { path: './suscriptions', title: 'Suscripciones', icon: 'nc-settings', class: '', role: [1, 2, 5] },
  { path: './agreements', title: 'Convenios', icon: 'nc-settings', class: '', role: [1, 2, 5] },
  { path: './parking-list', title: 'Lista de Parqueos', icon: 'nc-delivery-fast', class: '', role: [1, 2, 5] },
  { path: './configuration', title: 'Configuracion', icon: 'nc-delivery-fast', class: '', role: [1, 2] },
];

@Component({
  selector: 'app-parking-layout',
  templateUrl: './parking-layout.component.html',
  styleUrls: ['./parking-layout.component.scss']
})
export class ParkingLayoutComponent implements OnInit {
  public menuItems: any[];
  public user = {
    roleId: 1
  };

  constructor(private router: Router) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.menuItems = ROUTES.filter(menuItem => {
      for (let j = 0; j < menuItem.role.length; j++) {
        const r = menuItem.role[j];
        if (r === this.user.roleId) {
          return r;
        }
      }
    });

  }


  goToOnBoarding() {
    this.router.navigate(['/onBoarding']);
  }
}
