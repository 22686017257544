import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    role: any;
}

export const ROUTES: RouteInfo[] = [
    { path: './dashboard', title: 'Inicio', icon: 'nc-align-center', class: '', role: [1, 2, 3, 4] },
    { path: './carretera', title: 'Carretera', icon: 'nc-ambulance', class: '', role: [1, 2, 3] },
    { path: './urbanero', title: 'Urbanero', icon: 'nc-delivery-fast', class: '', role: [1, 2, 3] },
    { path: './exportacion', title: 'Exportacion', icon: 'nc-planet', class: '', role: [1, 2, 3] },
    { path: './reposicion', title: 'Reposicion', icon: 'nc-app', class: '', role: [1, 2, 3] },
    { path: './reenturneCa', title: 'Reenturne Carretera', icon: 'nc-ambulance', class: '', role: [1, 2, 3] },
    { path: './reenturneUr', title: 'Reenturne Urbanero', icon: 'nc-delivery-fast', class: '', role: [1, 2, 3] },
    { path: './gestion', title: 'Gestion', icon: 'nc-briefcase-24', class: '', role: [1, 2, 3] },
    { path: './reporte', title: 'Reportes', icon: 'nc-bullet-list-67', class: '', role: [1, 2, 4] },
    { path: './parking-report', title: 'Reportes de parqueadero', icon: 'nc-bullet-list-67', class: '', role: [1, 2, 4] },
    { path: './empresas', title: 'empresas', icon: 'nc-bank', class: '', role: [1, 2, 3] },
    { path: './conductores', title: 'conductores', icon: 'nc-single-02', class: '', role: [1, 2, 3] },
    { path: './clientes', title: 'Clientes', icon: 'nc-badge', class: '', role: [1, 2, 3] },
    { path: './usuarios', title: 'Usuarios', icon: 'nc-single-02', class: '', role: [1, 2] },
    { path: './booking', title: 'Booking', icon: 'nc-map-big', class: '', role: [1, 2, 3, 4] },
    { path: './configuracion', title: 'Configuración', icon: 'nc-settings', class: '', role: [1, 2] },
    { path: './configuration-parking', title: 'Configuración de parqueadero', icon: 'nc-settings', class: '', role: [1, 2] },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public user = {
        roleId: 1
    };

    constructor(private router: Router) { }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.menuItems = ROUTES.filter(menuItem => {
            for (let j = 0; j < menuItem.role.length; j++) {
                const r = menuItem.role[j];
                if (r === this.user.roleId) {
                    return r;
                }
            }
        });

    }

    goToOnBoarding() {
        this.router.navigate(['/onBoarding']);
    }
}
