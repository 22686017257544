import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import { ConfigService } from "app/services/config.service";
import { MasterService } from "app/services/master.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

@Component({
  selector: "app-onBoarding",
  templateUrl: "./onBoarding.component.html",
  styleUrls: ["./onBoarding.component.css"],
})
export class OnBoardingComponent implements OnInit {
  public authForm: FormGroup;
  public Submitted = false;
  public token: any;
  public parks: any[] = [];
  public isParking = false;
  public userId = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private configService: ConfigService,
    private masterService: MasterService
  ) {
    this.authForm = this.formBuilder.group({
      login: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getParks();
  }

  get af() {
    return this.authForm.controls;
  }

  getParks() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.userId = user.roleId;
    this.isParking = this.userId == 1 || this.userId == 5 || this.userId == 2;
    if (this.isParking) {
      this.masterService.getParks().subscribe(
        (res: any) => {
          if (
            this.configService.getConfig().url ==
            "https://pacificode.co/pikibackend-caucana"
          ) {
            for (let i = 0; i < res.length; i++) {
              const p = res[i];
              if (p.name == "Caucana") {
                this.parks.push(p);
                localStorage.setItem("park", JSON.stringify(p));
              }
            }
          } else if (
            this.configService.getConfig().url ==
            "https://pacificode.co/pikibackend"
          ) {
            for (let i = 0; i < res.length; i++) {
              const p = res[i];
              if (p.name == "Principal") {
                this.parks.push(p);
                localStorage.setItem("park", JSON.stringify(p));
              }
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  navigateTo(goTo: string) {
    this.router.navigate([goTo]);
  }
}
