import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { OnBoardingComponent } from './pages/onBoarding/onBoarding.component';
import { AuthGuard } from './auth.guard';
import { ParkingLayoutComponent } from './layouts/parking-layout/parking-layout.component';


export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, {
    path: '',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
  }]},
  {
    path: 'onBoarding',
    canActivate: [AuthGuard],
    component: OnBoardingComponent
  },
  {
    path: 'parking',
    canActivate: [AuthGuard],
    component: ParkingLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: () => import('./layouts/parking-layout/parking-layout.module').then(m => m.ParkingLayoutModule)
  }]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
]
